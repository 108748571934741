import React from "react";
import { Container } from "react-bootstrap";
import Particle from "../Particle";

function contactMe() {
  return (
    <Container fluid className="about-section" style={{height:'95.6vh'}}>
      <Particle />

          <h2 class="h1-responsive font-weight-bold text-center my-4">Contact me</h2>
  
          <p class="text-center w-responsive mx-auto mb-5">If you would like to get in contact with me, feel free to call, text, or email!</p>

          <div class="row">

      
            <div class="mx-auto col-md-3 text-center">
              <ul class="list-unstyled mb-0">
                <li><i class="fas fa-map-marker-alt fa-2x"></i>
                    <p>Cell: 205-937-9406</p>
                </li>

                <li><i class="fas fa-phone mt-4 fa-2x"></i>
                    <p>Email: tkadams1@crimson.ua.edu</p>
                </li>

              </ul>
             </div>
        

          </div>
    </Container>
  );
}

export default contactMe;

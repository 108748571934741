import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/tayloradams.jpg";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";


function Home() {
  return (
    <section className = "html">
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7}>
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hi There!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                  👋🏻
                </span>
              </h1>

              <h1 className="heading-name">
                I'm
                <strong className="main-name"> Taylor Adams</strong>
              </h1>

              <div style={{ padding: 50, textAlign: "left", fontsize: 7}}>
                <Type />
              </div>
            </Col>

            <Col md={5}style={{ paddingLeft: 70}}>
              <img
                src={homeLogo}
                alt="home pic"
                style={{ position: "relative", height: "auto", width: "80%", borderRadius: "20%"}}
                className="img-responsive center-block"
                
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import digitalPot from "../../Assets/Projects/DigitalPot.png";
import knapsack from "../../Assets/Projects/knapsack.png";
import ccourse from "../../Assets/Projects/Udemy_Certificate.png";
import webPort from "../../Assets/Projects/preview.png";
import ciscoPacketTracer from "../../Assets/Projects/network overview.png";
import PsuedoRand from "../../Assets/Projects/psuedoRand.png";
import SeedLabsCard from "./SeedLabsCard";
import SecKeyEnc from "../../Assets/Projects/secretKeyEnc.png";
import RSA from "../../Assets/Projects/RSA.png";
import PKI from "../../Assets/Projects/PKI.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Projects </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={digitalPot}
              isBlog={false}
              title="Arduino Shield for Go-Kart Drive Motor System"
              description="Code for driving the Arduino shield including a python serial monitor program, ROS2 subscriber code, and the necessary code to Drive the arduino. Also include is the custom arduino shield KiCad files to create the shield itself."
              link="https://github.com/tkadams1/ArduinoShieldMCP4151"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={knapsack}
              isBlog={false}
              title="Automated 0/1 Knapsack problem solver"
              description="Knapsack Automated solver created to verify homework accuracy of the hand done 0/1 knapsack problem."
              link="https://github.com/tkadams1/KnapsackProblemHomework3CS"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ciscoPacketTracer}
              isBlog={false}
              title="Cisco Packet Tracer: Network Design Project"
              description="Utilized Cisco packet tracer to configure a multi-site network for a ficticious company.  All routers, servers, computers, and IOT devices configured to simulate a real network."
              link="https://github.com/tkadams1/CiscoPacketTracerNetworkDesign"
            />
        </Col>
        </Row>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={webPort}
              isBlog={false}
              title="Portfolio Website"
              description="This portfolio website made with react js.  (Original template made by @soumyajit4419 on github. Modified by me)"
              link="https://github.com/tkadams1/Portfolio"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ccourse}
              isBlog={false}
              title="C practice project"
              description="All the content I completed for the udemy course: https://www.udemy.com/course/c-programming-for-beginners-/"
              link="https://github.com/tkadams1/CcoursePractice"
            />
          </Col>
        <Col md={4} className="project-card">
            <SeedLabsCard
              imgPath={PsuedoRand}
              isBlog={false}
              title="SeedLabs: Psuedo-Random Number Generator"
              description="The goal of the lab is to allow the user to gain a greater understanding of pseudo-random number generation.  This lab demonstrates the use of secure and non-secure ways to generate randomness."
              link="https://github.com/tkadams1/SeedLabs/blob/main/Psuedo-Random%20Number%20generation%20Lab/Lab%202%20CS542%20TaylorAdams.pdf"
              link2="https://github.com/tkadams1/SeedLabs/tree/main/Psuedo-Random%20Number%20generation%20Lab"
            />
        </Col>
        </Row>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={4} className="project-card">
            <SeedLabsCard
              imgPath={PKI}
              title="SeedLabs: Public Key Infrastructure"
              description="The goal of the lab is to allow the user to gain a greater understanding of Public key infrastructure and the role of Certificate Authorities. This lab allows the user to demonstrate the role of a certificate authority by becoming one on their VM. This lab also requires the user to perform a man- in-the-middle attack to give the user a greater understanding of vulnerabilities that can occur in PKI."
              link="https://github.com/tkadams1/SeedLabs/blob/main/Public%20Key%20infrastructure%20Lab/Public%20Key%20Infrastructure%20Lab%20Taylor%20Adams.pdf"
              link2="https://github.com/tkadams1/SeedLabs/tree/main/Public%20Key%20infrastructure%20Lab"
            />
        </Col>
        <Col md={4} className="project-card">
            <SeedLabsCard
              imgPath={RSA}
              title="SeedLabs: RSA public key Encryption and Signature"
              description="The goal of the lab is to allow the user to gain a greater understanding of RSA Public key encryption and the bignums library.  This lab demonstrates the use of secure and non-secure ways to generate randomness.  This lab also has the user demonstrate signing a certificate and manually verifying an X.509 certificate from a certificate authority.  This lab gives the user a hands-on understanding of the importance of RSA encryption in web security."
              link="https://github.com/tkadams1/SeedLabs/blob/main/RSA%20Public-Key%20Encryption%20and%20Signature%20Lab/Lab%203%20CS542%20TaylorAdamsPDF.pdf"
              link2="https://github.com/tkadams1/SeedLabs/tree/main/RSA%20Public-Key%20Encryption%20and%20Signature%20Lab"
            />
        </Col>
        <Col md={4} className="project-card">
            <SeedLabsCard
              imgPath={SecKeyEnc}
              title="SeedLabs: Secret Key Encryption"
              description="This lab demonstrates the use of secure and non-secure encryption algorithms. Some encryptions are not secure by having the user take advantage of their flaws to decrypt the messages without the keys or use available information to find the key. I was able to follow each of the task to crack a monoalphabetic cipher using frequency analysis, to determine which encryption modes are secure vs not secure, to create several programs to run known-plaintext attacks, chosen- plaintext attacks, and dictionary attacks. My results display the weaknesses of certain algorithms as described by the lab"
              link="https://github.com/tkadams1/SeedLabs/blob/main/RSA%20Public-Key%20Encryption%20and%20Signature%20Lab/Lab%203%20CS542%20TaylorAdamsPDF.pdf"
              link2="https://github.com/tkadams1/SeedLabs/tree/main/Secret%20Key%20Encryption%20Lab"
            />
        </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;

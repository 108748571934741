import React from "react";
import { Container, Row, Col, Button} from "react-bootstrap";
import { AiFillGithub} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { CgGitFork } from "react-icons/cg";
import {AiFillStar,} from "react-icons/ai";

function Footer() {
  return (
    <Container fluid className="footer">
      <Row>
        <Col sm="3"> </Col>
        <Col sm="6" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href="https://github.com/tkadams1"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <AiFillGithub />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.linkedin.com/in/taylor-adams09"
                style={{ color: "white" }}
                target="_blank" 
                rel="noopener noreferrer"
              >
                <FaLinkedinIn />
              </a>
            </li>
            
          </ul>
        </Col>
        
        <Col sm="3">
        <Button
                href="https://github.com/tkadams1/Portfolio"
                target="_blank"
                className="fork-btn-inner float-right"
                alignItems="left"
              >
                <CgGitFork style={{ fontSize: "1.2em" }} />{" "}
                <AiFillStar style={{ fontSize: "1.1em" }} />
              </Button>
        </Col>
        
      </Row>
    </Container>
  );
}

export default Footer;

import React from "react";
import Card from "react-bootstrap/Card";
import { Col } from "react-bootstrap";
import InfoCard from "./InfoCard";


function EduCard() {
  return (
    <Col>
    <InfoCard 
    degreeComplete= {true}
    degreeType= "Master of Science"
    degree="Computer Science" 
    university="The University of Alabama" 
    graduationDate="May 2023" 
    gpa="4.0/4.0"/>


    <InfoCard 
    degreeType= "Master of Science" 
    degree="Management Information Systems" 
    university="The University of Alabama" 
    graduationDate="May 2022" 
    gpa="3.9/4.0" />

    <InfoCard 
    degreeType= "Bachelor of Science"
    honors="magna cum laude"
    degree="Commerce and Business Administration" 
    university="The University of Alabama"
    graduationDate="December 2020" 
    gpa="3.8/4.0"
    major="Management Information Systems"
    minor="Italian" />
    </Col>
  );
}

export default EduCard;

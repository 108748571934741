import React from "react";
import Card from "react-bootstrap/Card";
import { BsArrowReturnRight } from "react-icons/bs";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            I'm <span className="purple">Taylor Adams </span>
            from <span className="purple"> Birmingham, Alabama.</span>
           <br />I attended <span className="crimson"> The University of Alabama</span> from 2016-2023, obtained a bachelors degree in <span className="purple"> Management Information Systems</span> and master's degrees in <span className="purple"> Computer Science </span> and <span className="purple"> Management Information Systems.</span>
            <br />
            <br />Currently, I work for <span className="purple"> Boeing </span> as a <span className="crimson"> Software Engineer</span>.   I am always passionate about learning new technologies and building software applications. 
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
            <BsArrowReturnRight/> Play/Watch baseball
            </li>
            <li className="about-activity">
            <BsArrowReturnRight/> Learn about upcoming technologies
            </li>
            <li className="about-activity">
            <BsArrowReturnRight/> Travel
            </li>
          </ul>

         
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;

import React from "react";
import Card from "react-bootstrap/Card";
import { Row } from "react-bootstrap";

const InfoCard = ({ degreeComplete, degreeType, degree, university, graduationDate, gpa, major, minor, honors}) => {
    let bachelorAdditionalInfo = null;
    let anticipated = null;
    let candidateFor = null;
    // checks if degree is complete and adds "Anticipated" and "Candidate for" to the degree
    if (degreeComplete === false) {
        anticipated = "Anticipated: ";
        candidateFor = "Candidate for ";

    }
    if (honors != null) {
        honors = <i>{honors}</i>;
    }

    // checks if major and minor are not null and adds them to the card
    if (major != null && minor != null) {
        bachelorAdditionalInfo = (
        <div>
            <Row style={{ textAlign: "center" }}>
                <span>Major: <span className="purple"><b> {major}</b></span> </span>
                </Row>
                <Row>
                <span>Minor: <span className="purple"> <b>{minor}</b></span></span>
            </Row>
        </div>
        );
    }
    return (
    <Card className="quote-card-view">
         <Card.Body>
        <div className="blockquote mb-0">
            <p className="edu-p">
            {candidateFor} <span className="purple"> {degreeType} in <b>{degree} </b></span>
                    <Row>
                    {honors}
                    </Row>
                    <Row>
                    <span className="crimson"> {university}</span>
                    </Row>
                    <span>{anticipated} Graduation: {graduationDate}</span>&emsp; <span>GPA: {gpa}</span>
                    <br />
                    {bachelorAdditionalInfo}
                </p>
        </div>
        </Card.Body>
    </Card>
      
  );
};

export default InfoCard;


import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PaperCard from "./PaperCards";
import Particle from "../Particle";

function Papers() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Papers </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are some of the papers I've written.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <PaperCard
              isBlog={false}
              title="How Cryptography is used in Bitcoin"
              description="Abstract — Over the past decade Bitcoin and blockchain technology have ballooned into a major world currency. At the time of writing Bitcoin’s market cap is at $777 billion dollars, with the global cryptocurrency market cap at $1.9 trillion dollars. How is all this capital protected? What cryptographic measures take place in the bitcoin algorithm to ensure the authenticity and ownership of every Satoshi (Bitcoin cent)? This paper aims to provide the answers to these questions and provide the reader with an understanding of the cryptography behind the Bitcoin network."
              link="https://github.com/tkadams1/Papers/blob/main/How%20Cryptography%20is%20used%20in%20Bitcoin.pdf"
            />
          </Col>

          <Col md={4} className="project-card">
            <PaperCard
              isBlog={false}
              title="Software Security In Autonomous Vehicles"
              description="Abstract— This paper
              aims to outline the importance of software security in autonomous
              vehicles, the challenges of creating autonomous vehicle software,
              and past attacks against vehicles and autonomous vehicle subsystems. 
              This is followed by proposed methods on how to develop
              secure software in autonomous vehicles and a proposition for
              vehicle manufactures to utilize the Rust programming language.
              This paper also reviews the life cycle phases of secure vehicle
              software engineering. This includes analyzing, scanning, and
              testing vehicle software for vulnerabilities. Proposed frameworks
              for utilizing deep learning for intrusion detection of autonomous
              vehicle systems are also investigated."
              link="https://github.com/tkadams1/Papers/blob/main/Software_Security_in_Autonomous_Vehicles-Final.pdf"
            />
          </Col>

          <Col md={4} className="project-card">
            <PaperCard
              isBlog={false}
              title="The correlation between Cryptocurrency market trends and the corresponding Twitter sentiment analysis  (Co-Authored)"
              description="Abstract — This paper discusses the correlation between the value of Cryptocurrencies (Bitcoin, Ethereum and Shiba Inu) and the sentiment of their appearances in ‘Tweets’ on the social media platform Twitter. 
               Our team utilized the twitter api along with bitcoin price data to run correlation analysis utilizing python."
              link="https://github.com/tkadams1/Papers/blob/main/CryptoSentimentResearchPaper.pdf"
            />
          </Col>

          <Col md={4} className="project-card">
            <PaperCard
              isBlog={false}
              title="The Blockchain Recipe: Building a blockchain from scratch for businesses"
              description="Abstract — Public blockchains such as Bitcoin’s and Ethereum’s continue to gain significant traction with investors.  Private blockchains, while lesser-known, can fill the place of a database when certain criteria are met.  But when should a blockchain be utilized in a business environment? How does one go about understanding the details of how blockchains operate? This paper attempts to provide the reader with a generalized and slightly technical understanding of blockchain and the business tools currently available to implement a blockchain in a business environment, such as Hyperledger Fabric.em."
              link="https://github.com/tkadams1/Papers/blob/main/The%20Blockchain%20Recipe-%20How%20to%20build%20a%20blockchain%20from%20scratch%20for%20business.pdf"
            />
          </Col>
          
        </Row>
      </Container>
    </Container>
  );
}

export default Papers;
